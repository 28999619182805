var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.title}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Choose due date","label-for":"due_date"}},[_c('validation-provider',{attrs:{"name":"due_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"min":_vm.min,"locale":"en"},model:{value:(_vm.form.due_date),callback:function ($$v) {_vm.$set(_vm.form, "due_date", $$v)},expression:"form.due_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Choose time","label-for":"time"}},[_c('validation-provider',{attrs:{"name":"time","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{attrs:{"locale":"en"},model:{value:(_vm.form.time),callback:function ($$v) {_vm.$set(_vm.form, "time", $$v)},expression:"form.time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Package Count","label-for":"packages_count"}},[_c('validation-provider',{attrs:{"name":"package count","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"packages_count","state":errors.length > 0 ? false:null,"placeholder":"Package Count"},model:{value:(_vm.form.packages_count),callback:function ($$v) {_vm.$set(_vm.form, "packages_count", $$v)},expression:"form.packages_count"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Choose the address","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"address","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.addresses,"label":"name"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Notes","label-for":"v-textarea"}},[_c('b-form-textarea',{attrs:{"id":"v-textarea","placeholder":"Notes","rows":"3"},model:{value:(_vm.form.notes),callback:function ($$v) {_vm.$set(_vm.form, "notes", $$v)},expression:"form.notes"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"v-create_shipment"}},[_c('b-form-checkbox',{attrs:{"value":"1"},model:{value:(_vm.create_shipment),callback:function ($$v) {_vm.create_shipment=$$v},expression:"create_shipment"}},[_vm._v(" Create new Shipment ")])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"v-upload_file"}},[_c('b-form-checkbox',{attrs:{"value":"1"},model:{value:(_vm.upload_file),callback:function ($$v) {_vm.upload_file=$$v},expression:"upload_file"}},[_vm._v(" Upload File ")])],1)],1),_c('b-col',{attrs:{"md":"5"}}),_c('b-col',{attrs:{"md":"6"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"type":"submit","variant":"warning"},on:{"click":function($event){$event.preventDefault();return _vm.cancel($event)}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }