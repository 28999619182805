<template>
  <b-card :title="title">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Choose due date"
              label-for="due_date"
            >
              <validation-provider
                #default="{ errors }"
                name="due_date"
                rules="required"
              >
                <b-form-datepicker
                  v-model="form.due_date"
                  :min="min"
                  locale="en"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Choose time"
              label-for="time"
            >
              <validation-provider
                #default="{ errors }"
                name="time"
                rules=""
              >
                <b-form-timepicker
                  v-model="form.time"
                  locale="en"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Package Count"
              label-for="packages_count"
            >
              <validation-provider
                #default="{ errors }"
                name="package count"
                rules=""
              >
                <b-form-input
                  id="packages_count"
                  v-model="form.packages_count"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Package Count"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Choose the address"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                name="address"
                rules="required"
              >
                <v-select
                  id="address"
                  v-model="form.address"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="addresses"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Notes"
              label-for="v-textarea"
            >
              <b-form-textarea
                id="v-textarea"
                v-model="form.notes"
                placeholder="Notes"
                rows="3"
              />

            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label=""
              label-for="v-create_shipment"
            >
              <b-form-checkbox
                v-model="create_shipment"
                value="1"
              >
                Create new Shipment
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label=""
              label-for="v-upload_file"
            >
              <b-form-checkbox
                v-model="upload_file"
                value="1"
              >
                Upload File
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col md="5" />
          <b-col md="6">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              type="submit"
              variant="warning"
              @click.prevent="cancel"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { required } from '@core/utils/validations/validations'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BCol,
  BForm, BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormTimepicker,
  BRow,
} from 'bootstrap-vue'
import { debounce } from 'lodash'
import vSelect from 'vue-select'
import axios from '@/libs/axios'

export default {
  name: 'PickupsFormVue',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTimepicker,
    BFormTextarea,
    BFormCheckbox,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // 15th two months prior
    const minDate = new Date(today)
    /* minDate.setMonth(minDate.getMonth() - 2)
    minDate.setDate(15) */
    // 15th in two months
    // const maxDate = new Date(today)
    /* maxDate.setMonth(maxDate.getMonth() + 2)
    maxDate.setDate(15) */
    return {
      title: this.$route.params.id ? 'Edit pickup' : 'Add new pickup',
      form: {
        client_id: null,
        due_date: null,
        address: null,
        time: null,
        packages_count: null,
        notes: null,
      },
      required,
      url: this.$route.params.id ? `/pickups/${this.$route.params.id}` : '/pickups/',
      clients: [],
      statuses: [],
      min: minDate,
      create_shipment: false,
      upload_file: false,
      addresses: [],
      // max: maxDate,
    }
  },
  mounted() {
    this.addresses = JSON.parse(localStorage.getItem('userData')).addresses
    if (this.$route.params.id) {
      this.getData()
      this.getStatues()
    }
  },
  methods: {
    validationForm() {
      if (this.$route.params.id) {
        // eslint-disable-next-line no-underscore-dangle
        this.form._method = 'PUT'
      }
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.form.client_id = JSON.parse(localStorage.getItem('userData')).id
          axios.post(this.url, this.form).then(resp => {
            if (resp) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Data is submitted successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (this.create_shipment) {
                this.$router.push(`/shipment/create?pickup_id=${resp.data.data.id}`)
              } else if (this.upload_file) {
                this.$router.push(`/shipment/uploadFile?pickup_id=${resp.data.data.id}`)
              } else {
                this.$router.push('/pickup')
              }
            }
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'some error occurred',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    getData() {
      axios.get(`/pickups/${this.$route.params.id}`).then(resp => {
        this.form = resp.data.data
      })
    },
    cancel() {
      this.$router.push('/pickup')
    },
    searchClient(search, loading) {
      if (search) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: debounce((loading, search, vm) => {
      axios.post('/clients/search', {
        search: { value: search },
      }).then(res => {
        // eslint-disable-next-line no-param-reassign
        vm.clients = res.data.data
        loading(false)
      })
    }, 350),
    getStatues() {
      axios.get('/pickupStatuses/').then(resp => {
        this.statuses = resp.data.data
      })
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
